import "./index.css";

import React from "react";
import ReactDOM from "react-dom/client";
// import reportWebVitals from './reportWebVitals';
import {
  BrowserRouter,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
} from "react-router-dom";
import { HomePageContainer } from "./pages/home/home";
import { EditPageContainer } from "./pages/edit/edit";

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <HomePageContainer />,
//   },
//   { path: "/edit?slug=:slug", element: <EditPageContainer /> },
// ]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
// <React.StrictMode>
// </React.StrictMode>
//   {/* <BrowserRouter basename="home"> */}
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<HomePageContainer />} />
      <Route path="/x/edit" element={<EditPageContainer />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
