import axios from "axios";
import { newErrBadResponse } from "../utils/errors";
import { sanitizeSlug } from "../utils/url_helpers";
import { TEditUrl, TUrl } from "./types";
import { api_callAxios } from "./utils";

export async function api_editUrl(
  url_id: string | null,
  url: TEditUrl
): Promise<TUrl> {
  if (!url_id) {
    throw Error("Please provide a url id for edit.");
  }

  const sanitizedSlug = sanitizeSlug(url.slug);
  if (sanitizedSlug === "") {
    throw Error("Slug contains invalid character(s).");
  }

  const respData = await api_callAxios(`/url/edit/${url_id}`, "post", url, null);

  if (!(respData as Object).hasOwnProperty("_res") || respData._res !== "ok") {
    throw newErrBadResponse(
      respData.code ? respData.code : `Please contact developer with code: UE28`
    );
  }

  const copy = { ...respData };
  delete copy._res;

  return copy as TUrl;
}
