import { ReactModel } from "df-react-model/dist/react-model/react-model";
import { keyLockr_ssoInit } from "./sso-init";
import { Modal } from "bootstrap";
import { TModelInfo } from "../api/types";
import { toast } from "react-toastify";

export function loginLogic(
  model_user_info: ReactModel<TModelInfo>,
  qrcode_modal_id: string
) {
  if (model_user_info.s.user_id) {
    console.log("User is already logged in.");
  }

  const onSuccess = () => {
    closeQRCodeDialog(qrcode_modal_id);
    window.location.assign("/");
  };

  const setSsoLink = (sso_link: string) => {
    model_user_info.setProperty({ sso_link: sso_link });
  };

  const onFail = (msg: string) => {
    toast.warn("Please close QR Code popup and try login again.");
  };

  keyLockr_ssoInit(setSsoLink, onSuccess, onFail);

  openQRCodeDialog(qrcode_modal_id);
}

function openQRCodeDialog(qrcode_modal_id: string) {
  const element: Element | null = document.getElementById(qrcode_modal_id);
  if (!element) {
    toast.warn("Please contact the developer with code: L35.");
    return;
  }

  const modal = new Modal(element);
  modal.show();
}

function closeQRCodeDialog(qrcode_modal_id: string) {
  const element: Element | null = document.getElementById(qrcode_modal_id);
  if (!element) {
    toast.warn("Please contact the developer with code: L35.");
    return;
  }
  const modal = new Modal(element);
  modal.hide();
}
