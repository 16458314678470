import axios from "axios";
import { TNewUrl } from "../components/navbar";
import { newErrBadResponse } from "../utils/errors";
import { sanitizeUrl } from "../utils/url_helpers";
import { TUrl } from "./types";
import { api_callAxios } from "./utils";

export const api_submitNewUrl = async (
  url: string,
  selected: any
): Promise<TUrl> => {
  const sanitzedUrl = sanitizeUrl(url);

  if (sanitzedUrl === "") {
    throw Error("Invalid URL.");
  }

  const data: TNewUrl = {
    domain_id: selected?.id,
    url: sanitzedUrl,
    slug: "",
    pswd: "",
    expiry: "",
  };
  const respData = await api_callAxios("/url/add", "post", data, null);

  if (!(respData as Object).hasOwnProperty("_res") || respData._res !== "ok") {
    throw newErrBadResponse(
      respData.code ? respData.code : `Failed to add url.`
    );
  }

  delete respData._res;
  return respData as TUrl;
};
