import "bootstrap/dist/css/bootstrap.css";

import { ReactModel } from "df-react-model/dist/react-model/react-model";
import clsx from "clsx";
import QRCode from "qrcode.react";

import styles from "./home.module.scss";
import { api_loadUrlList } from "../../api/url-list";
import { SyntheticEvent, useEffect } from "react";
import { NavBar } from "../../components/navbar";
import { loginLogic } from "../../keylockr/login";
import { TModelInfo, TUrl } from "../../api/types";
import { api_loadUser } from "../../api/load-user";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { api_deleteUrl } from "../../api/url-delete";
import { toast } from "react-toastify";
import { TKeyLockr_UserInfo } from "../../keylockr/sso-init";
import { errBadResponse, errNoUser, newErrUnknown } from "../../utils/errors";
import { api_loadDomains } from "../../api/domains-list";

//----------------------------------------------------------------
// Model
//----------------------------------------------------------------
const model_user = new ReactModel<TKeyLockr_UserInfo>({
  model: {
    user_id: "",
    user_nickname: "",
  },
});

const model_info = new ReactModel<TModelInfo>({
  model: {
    user_id: "",
    name: "",
    user_nickname: "",
    url_list: [],
    tmp_id: "",
    sso_link: undefined,
    qr_timeout: true,
    counter: 0,
    interval_id: undefined,

    domain_list: undefined,
  },
});

type TModelNewUrl = {
  url: string;
  slug: string;
  password: string;
  expiry: string;
};

const newly_created = new ReactModel<TModelNewUrl>({
  model: {
    url: "",
    slug: "https://dev.gjw.us/placeholder",
    password: "",
    expiry: "",
  },
});

//----------------------------------------------------------------
// Controller
//----------------------------------------------------------------
async function initiatePage() {
  try {
    const resp = await api_loadUser();

    model_user.setProperty({
      user_id: resp.user_id,
      user_nickname: resp.user_nickname,
    });

    const url_list = await api_loadUrlList();
    model_info.setProperty({
      url_list: url_list ?? [],
    });
  } catch (e) {
    if (!(e instanceof Error)) {
      toast.warn(newErrUnknown("H80").message);
      return;
    }

    switch (e.name) {
      case errBadResponse: {
        toast.warn(e.message);
        break;
      }
      case errNoUser: {
        break;
      }
      default: {
        toast.warn(newErrUnknown("H108").message);
      }
    }
  }

  // load domains regardless of login status.
  try {
    const domain_list = await api_loadDomains();
    model_info.setProperty({ domain_list: domain_list });
  } catch (e) {
    if (!(e instanceof Error)) {
      toast.warn(newErrUnknown("H102").message);
      return;
    }
    toast(e.message);
  }
}

const qrcode_modal_id = "qrcode_modal";

//----------------------------------------------------------------
// View
//----------------------------------------------------------------

export let navigate: NavigateFunction | undefined;

/**
 * Entry point.
 */
export function HomePageContainer() {
  navigate = useNavigate();

  const login_logic = () => loginLogic(model_info, qrcode_modal_id);

  useEffect(() => {
    initiatePage();
  }, []);

  return (
    <div className={clsx(styles.home_page_container)}>
      <NavBar
        loginLogic={login_logic}
        model_user={model_user}
        model_domain_list={model_info.getModel("domain_list")}
        model_info={model_info}
        submit={true}
      />

      <div className={styles.title_container}>
        <h2>Home</h2>
      </div>

      <div className={clsx(styles.url_table_container_bootstrap)}>
        <UrlTable />
      </div>

      <QRCodeDialog />
    </div>
  );
}

function QRCodeDialog() {
  // Refer to this link for more modal stuff:
  // https://getbootstrap.com/docs/5.2/components/modal/#modal-components

  model_info.useModel();

  return (
    <div className={clsx("modal", styles.qrcode_modal)} id={qrcode_modal_id}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Login via KeyLockr</h5>
          </div>
          <div className="modal-body">
            {model_info.s.sso_link ? (
              <>
                <p>
                  Scan the QR Code with KeyLockr to login and access advanced
                  features!
                </p>
                <QRCode style={{border: "2px solid white"}} value={model_info.s.sso_link} />
              </>
            ) : (
              <button type="button" className="btn btn-secondary">
                Refresh QR Code
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function EditButton(id: string): JSX.Element {
  function routeToEditPage(e: SyntheticEvent, id: string) {
    navigate?.(`/x/edit?id=${id}`, { state: { test: "hi" } });
  }
  return (
    <button onClick={(e) => routeToEditPage(e, id)}>
      <img src="/images/pen.svg" />
    </button>
  );
}

function DeleteButton(id: string, hash: string): JSX.Element {
  const deleteItem = async () => {
    try {
      await api_deleteUrl(id, hash);
      let copy: TUrl[] = Object.assign([], model_info.s.url_list);
      const index = copy.findIndex((item) => item.id === id);
      copy.splice(index, 1);
      model_info.setProperty({ url_list: copy });
    } catch (e) {
      if (e instanceof Error) {
        toast.warn(e.message);
        return;
      }
      toast.warn("Please contact the developer with error code: H177.");
    }
  };

  return (
    <button onClick={deleteItem}>
      <img src="/images/trash.svg" />
    </button>
  );
}

function passwordDisplay(p: string): string {
  if (p.length == 0) {
    return "";
  } else {
    return p;
  }
}

const sanitizeDisplayTime = (time: string): string => {
  const times = time.split(" ");
  return times[0];
};

function UrlTable(): JSX.Element {
  const head = (
    <thead>
      <tr>
        <th className={clsx(styles.id)}>Id</th>
        <th className={clsx(styles.shortened)}>Title</th>
        <th className={clsx(styles.shortened)}>OKUrl</th>
        <th className={clsx(styles.clicks)}>Clicks</th>
        <th className={clsx(styles.password)}>
          <div className={styles.password_container}>Password</div>
        </th>
        <th className={clsx(styles.expiry)}>Expiry</th>
        <th className={clsx(styles.edit)}>Edit</th>
        <th className={clsx(styles.added)}>Added</th>
        <th className={clsx(styles.modified)}>Modifed</th>
      </tr>
    </thead>
  );

  const u = model_info.useModel("url_list").state;
  if (u === undefined) {
    return <></>;
  }
  // TODO: report bug to paul
  try {
    u.map(() => {});
  } catch {
    model_info.setProperty({ url_list: Array.from(u) });
  }
  let rows = u.map((row, rowIdx) => {
    return (
      <tr key={rowIdx}>
        <td className={styles.item_id}>{row.id}</td>
        <td className={styles.link}>
          <p>{row.title}</p>
          <a href={row.url}>{row.url}</a>
        </td>
        <td className={styles.shortened}>
          <a target="_blank" rel="noopener noreferrer" href={row.okurl}>
            {row.okurl}
          </a>
        </td>
        <td>{row.clicks}</td>
        <td className={styles.password}>
          <div className={styles.password_container}>
            {passwordDisplay(row.pswd)}
          </div>
        </td>
        <td className={styles.expiry}>
          <div className={styles.expiry_container}>{row.expiry}</div>
        </td>
        <td className={styles.edit}>
          <div className={styles.edit_button_container}>
            {EditButton(row.id)}
            {DeleteButton(row.id, row.hash)}
          </div>
        </td>
        <td className={styles.added}>{row.atime}</td>
        <td className={styles.modified}>{row.mtime}</td>
      </tr>
    );
  });

  return (
    <table
      className={clsx(
        "table table-striped table-hover",
        styles.url_table_bootstrap
      )}
    >
      {head}
      <tbody>{rows}</tbody>
    </table>
  );
}

function LinkCreatedDialog() {
  newly_created.useModel();

  const dialog = (
    <dialog id="link_created_dialog">
      <div>
        <p>Link was created!</p>
        <input value={newly_created.s.slug}></input>
        <button onClick={() => undefined}>Close</button>
      </div>
    </dialog>
  );

  return dialog;
}
