import { HInput } from "df-react-model/dist/react-model-ui/form/h-input";
import { ReactModel } from "df-react-model/dist/react-model/react-model";
import { SyntheticEvent, useMemo } from "react";
import styles from "./navbar.module.scss";

import { TModelInfo, TOneDomain } from "../api/types";

import Modal from "bootstrap/js/dist/modal";
import clsx from "clsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { api_submitNewUrl } from "../api/url-add";
import { logout } from "../keylockr/logout";
import { TKeyLockr_UserInfo } from "../keylockr/sso-init";
import { Dialog_Submitted } from "./dialog-submit";

import { errBadResponse, newErrBadResponse } from "../utils/errors";

const submitted_dialog_id = "submited_dialog";

export function NavBar(props: {
  loginLogic: () => void;
  model_user: ReactModel<TKeyLockr_UserInfo>;
  model_domain_list: ReactModel<TOneDomain[] | undefined> | undefined;
  model_info: ReactModel<TModelInfo> | undefined;
  submit: boolean;
}) {
  return (
    <>
      <div className={styles.nav_bar_container}>
        <div className={styles.nav_bar}>
          <LeftSpacer />
          {props.submit && props.model_info && props.model_domain_list ? (
            <CenterWithSubmit
              model_domain_list={props.model_domain_list}
              model_info={props.model_info}
            />
          ) : (
            <CenterPlain />
          )}
          <RightSpacer
            loginLogic={props.loginLogic}
            model_user={props.model_user}
          />
        </div>
      </div>
      <Dialog_Submitted dialog_id={submitted_dialog_id} />
      <ToastContainer position="top-right" />
    </>
  );
}

function LeftSpacer() {
  return (
    <div className={styles.left_spacer}>
      <button onClick={(e) => routeToHomePage(e)}>OKUrl</button>
    </div>
  );
}

function routeToHomePage(e: SyntheticEvent) {
  e.preventDefault();
  window.location.href = `/`;
}

export type TNewUrl = {
  domain_id: string;
  url: string;
  slug: string;
  pswd: string;
  expiry: string;
};

type TSubmitter = {
  selected: TOneDomain | undefined;
  url: string;
};

// Nav bar center component with submit function.
export function CenterWithSubmit(props: {
  model_domain_list: ReactModel<TOneDomain[] | undefined>;
  model_info: ReactModel<TModelInfo>;
}) {
  const model_submitter: ReactModel<TSubmitter> = useMemo(() => {
    return new ReactModel<TSubmitter>({
      model: {
        selected: undefined,
        url: "",
      },
    });
  }, []);
  const { selected, url } = model_submitter.useModel().state;

  const domain_list = props.model_domain_list.useModel().state;
  if (selected === undefined && domain_list?.[0]) {
    model_submitter.setProperty({ selected: domain_list[0] });
  }

  let items: JSX.Element[] =
    domain_list?.map((item) => {
      const onClick = () => {
        model_submitter.setProperty({ selected: item });
      };
      return (
        <li key={item.id}>
          <a
            className={clsx(
              "dropdown-item",
              selected?.id === item.id ? "active" : ""
            )}
            href="#"
            onClick={onClick}
          >
            {item.domain}
          </a>
        </li>
      );
    }) ?? [];

  const onSubmit = async () => {
    try {
      debugger;
      const newUrl = await api_submitNewUrl(url, selected);
      // TODO: Use dialog instead of navigate.
      dialogGet(submitted_dialog_id, toast.warn)?.show();
      const copy = [...props.model_info.s.url_list];
      copy.unshift(newUrl);
      props.model_info.setProperty({ url_list: copy });
    } catch (e) {
      if (!(e instanceof Error)) {
        toast.warn("Please contact developers with error code: N134");
        return;
      }
      switch (e.name) {
        case errBadResponse:
          toast.warn(e.message);
          break;
        default:
          console.error(e);
          toast.warn(`Encountered error: ${e.message}.`);
      }
    }
  };

  return (
    <div className={clsx("input-group", styles.center)}>
      <button
        className="btn btn-outline-secondary dropdown-toggle"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {selected ? selected.domain : "-"}
      </button>
      <ul className="dropdown-menu">{items}</ul>
      <HInput
        className="form-control"
        model={model_submitter}
        field="url"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSubmit();
          }
        }}
      />
      <SubmitButton model_submitter={model_submitter} onSubmit={onSubmit} />
    </div>
  );
}

function dialogGet(
  dialog_id: string,
  toastWarn: (msg: any) => void
): Modal | null {
  const element: Element | null = document.getElementById(dialog_id);
  if (!element) {
    toastWarn("My code is perfect you messed up");
    return null;
  }
  const modal = new Modal(element);
  return modal;
}

export function CenterPlain(): JSX.Element {
  return <div className={clsx(styles.center_plain)}></div>;
}

function SubmitButton(props: {
  model_submitter: ReactModel<TSubmitter>;
  onSubmit: () => any;
}) {
  const url = props.model_submitter.useModel("url").state;

  if (url) {
    return (
      <div className={styles.submit_btn} onClick={props.onSubmit}>
        <img src="/images/allow-submit.svg" />
      </div>
    );
  } else {
    return (
      <div className={styles.submit_btn}>
        <img src="/images/no-submit.svg" />
      </div>
    );
  }
}

/** This is the portion that controls user login logout. */
function RightSpacer(props: {
  loginLogic: () => void;
  model_user: ReactModel<TKeyLockr_UserInfo>;
}) {
  return (
    <div className={styles.right_spacer}>
      <LoginButtonContainer
        loginLogic={props.loginLogic}
        model_user={props.model_user}
      />
      <UserProfileContainer model_user={props.model_user} />
    </div>
  );
}

function LoginButtonContainer(props: {
  loginLogic: () => void;
  model_user: ReactModel<TKeyLockr_UserInfo>;
}) {
  const { user_id, user_nickname } = props.model_user.useModel().state;

  return (
    <div
      className={styles.login_btn_con}
      style={{ display: user_id ? "none" : "flex" }}
    >
      <button
        onClick={async () => {
          props.loginLogic();
        }}
      >
        Login
      </button>
    </div>
  );
}

function UserProfileContainer(props: {
  model_user: ReactModel<TKeyLockr_UserInfo>;
}) {
  const { user_id, user_nickname } = props.model_user.useModel().state;

  if (!user_id) {
    return <></>;
  }

  return (
    <>
      <div className={styles.user_profile} style={{ display: "flex" }}>
        {user_nickname.substring(0, 2)}
      </div>
      <p>{user_id}</p>
      <button type="button" className="btn btn-danger" onClick={logout}>
        logout
      </button>
    </>
  );
}
