import axios from "axios";
import { newErrBadRequest } from "../utils/errors";
import { TUrl } from "./types";
import { api_callAxios } from "./utils";

export async function api_loadUrlDetail(id: string): Promise<TUrl> {
  if (!id) {
    throw newErrBadRequest("UL8");
  }

  const respData = await api_callAxios(`/url/detail/${id}`, "post", null, null);

  if (!(respData as Object).hasOwnProperty("_res") || respData._res !== "ok") {
    throw Error(
      respData.code ? respData.code : "Please contact the developer with code: UL20."
    );
  }

  return respData as TUrl;
}
