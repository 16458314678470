import { TKeyLockr_UserInfo } from "../keylockr/sso-init";
import { newErrBadResponse, newErrNoUser } from "../utils/errors";
import { api_callAxios } from "./utils";

export async function api_loadUser(): Promise<TKeyLockr_UserInfo> {
  try {
    const respData = await api_callAxios("/u/info", "post", null, null);

    if (!respData.hasOwnProperty("_res") || respData._res !== "ok") {

      throw newErrBadResponse(
        respData.code
          ? respData.code
          : "Please contact the developer with code: LU10."
      );
    }

    if (respData.user_nickname && respData.user_id) {
      return {
        user_id: respData.user_id,
        user_nickname: respData.user_nickname,
      };
    } else {
      throw newErrBadResponse(
        respData.code
          ? respData.code
          : "Please contact the developer with code: LU28."
      );
    }
  } catch (e) {
    console.error((e as Error).message);
    throw newErrNoUser();
  }
}
