import axios from "axios";
import { BE_EP, MOCK_MODE } from "../utils/globals";

export async function api_callAxios(
  url: string,
  method: string,
  data: any,
  mock_data: any
): Promise<any> {
  if (MOCK_MODE) {
    return mock_data;
  }

  const resp = await axios.request({
    url: BE_EP + url,
    method: method,
    data: data,
    headers: {
      Accept: "application/json",
    },
    withCredentials: true,
  });
  return resp.data;
}
