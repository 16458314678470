import clsx from "clsx";
import "./toggle.scss";

export function Dialog_Submitted(props: { dialog_id: string }) {
  // Refer to this link for more modal stuff:
  // https://getbootstrap.com/docs/5.2/components/modal/#modal-components

  return (
    <div className={clsx("modal")} id={props.dialog_id}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Link was created.</h5>
          </div>
          <div className="modal-body">
            Please click the pen icon to edit slug information.
          </div>
        </div>
      </div>
    </div>
  );
}
