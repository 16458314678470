export const errUnknown = "unknown_err";
export const newErrUnknown = (code: string): Error => {
  const e = new Error(
    `Encountered error. Please contact the developer with code: ${code}`
  );
  e.name = errUnknown;
  return e;
};

export const errBadRequest = "bad_request";
export const newErrBadRequest = (code: string): Error => {
  const e = new Error(
    `Bad request parameters. Please contact the developer with code: ${code}`
  );
  e.name = errUnknown;
  return e;
};

export const errWrongPage = "wrong_page";
export const newErrWrongPage = (): Error => {
  const e = new Error(`You visited an invalid URL.`);
  e.name = errWrongPage;
  return e;
};

export const errBadResponse = "bad_response";
export const newErrBadResponse = (msg: string): Error => {
  const e = new Error(`${msg}`);
  e.name = errBadResponse;
  return e;
};

export const errNoUser = "no_user";
export const newErrNoUser = (): Error => {
  const e = new Error("Not logged in.");
  e.name = errNoUser;
  return e;
};
