import { ReactModel } from "df-react-model/dist/react-model/react-model";

import styles from "./edit.module.scss";

import clsx from "clsx";
import { HInput } from "df-react-model/dist/react-model-ui/form/h-input";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { api_loadUser } from "../../api/load-user";
import { api_loadUrlDetail } from "../../api/url-detail";
import { api_editUrl } from "../../api/url-edit";
import { CenterPlain, NavBar } from "../../components/navbar";
import Toggle from "../../components/toggle";
import { TKeyLockr_UserInfo } from "../../keylockr/sso-init";
import {
  errBadRequest,
  errBadResponse,
  errNoUser,
  errWrongPage,
  newErrUnknown,
  newErrWrongPage,
} from "../../utils/errors";

//----------------------------------------------------------------
// Model
//----------------------------------------------------------------
const model_user = new ReactModel<TKeyLockr_UserInfo>({
  model: {
    user_id: "",
    user_nickname: "",
  },
});

export type TModelEditor = {
  original: {
    id: string;
    atime: string;
    mtime: string;
    domain_id: string;
    url: string; // original url
    slug: string;
    title: string;
    pswd: string;
    expiry: string;
    hash: string;
    okurl: string;
    clicks: string;
  };
  new: {
    url: string;
    slug: string;
    title: string;
    pswd: string;
    expiry: string;
  };
  pswd_enabled: boolean;
};
const model_editor = new ReactModel<TModelEditor>({
  model: {
    original: {
      id: "",
      atime: "",
      mtime: "",
      domain_id: "",
      url: "", // original url
      slug: "",
      title: "",
      pswd: "",
      expiry: "",
      hash: "",
      okurl: "",
      clicks: "",
    },
    new: {
      title: "",
      url: "",
      slug: "",
      pswd: "",
      expiry: "",
    },
    pswd_enabled: false,
  },
});

//----------------------------------------------------------------
// Controller
//----------------------------------------------------------------
async function initiatePage(id: string | null) {
  try {
    if (!id) {
      throw newErrWrongPage();
    }

    const resp_user = await api_loadUser();

    model_user.setProperty({
      user_id: resp_user.user_id,
      user_nickname: resp_user.user_nickname,
    });

    const resp_url = await api_loadUrlDetail(id);
    model_editor.setProperty({
      original: resp_url,
      new: {
        url: resp_url.url,
        slug: resp_url.slug,
        title: resp_url.title,
        pswd: resp_url.pswd,
        expiry: resp_url.expiry,
      },
      pswd_enabled: resp_url.pswd.length > 0,
    });
  } catch (e) {
    if (!(e instanceof Error)) {
      toast.warn(newErrUnknown("E118").message);
      return;
    }

    switch (e.name) {
      case errBadRequest || errWrongPage: {
        toast.warn(newErrWrongPage().message);
        break;
      }
      case errBadResponse: {
        toast.warn(e.message);
        break;
      }
      case errNoUser: {
        toast.warn("Please login first.");
        break;
      }
      default: {
        console.log(e.message);
        toast.warn(newErrUnknown("E108").message);
      }
    }
  }
}

const qrcode_modal_id = "qrcode_modal";

//----------------------------------------------------------------
// View
//----------------------------------------------------------------
export function EditPageContainer() {
  // TODO: use this instead of search params.
  // const { state } = useLocation();
  const [searchParams, _] = useSearchParams();
  const url_id = searchParams.get("id");

  useEffect(() => {
    initiatePage(url_id);
  }, []);

  model_editor.useModel();

  return (
    <div className={styles.edit_page_container}>
      <NavBar
        loginLogic={() => {}}
        model_user={model_user}
        model_domain_list={undefined}
        model_info={undefined}
        submit={false}
      />

      <div className={styles.title_container}>
        <h2>Editor</h2>
      </div>

      <Editor id={url_id} />
    </div>
  );
}

function Editor(props: { id: string | null }) {
  const original = model_editor.useModel("original").state;
  const new_stuff = model_editor.useModel("new").state;

  if (!props.id) {
    return <></>;
  }

  const onSubmit = async () => {
    try {
      const edited = await api_editUrl(props.id, new_stuff);
      toast.success("Changes saved successfully.");
    } catch (e) {
      if (!(e instanceof Error)) {
        toast.warn(newErrUnknown("E190").message);
        return;
      }
      toast.warn(e.message);
    }
  };

  return (
    <div className={styles.editor_container}>
      <label>Title</label>
      <HInput
        model={model_editor.getModel("new")}
        field="title"
        placeholder={original.title}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSubmit();
          }
        }}
      />

      <label>Target URL</label>
      <HInput
        model={model_editor.getModel("new")}
        field="url"
        placeholder={original.url}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSubmit();
          }
        }}
      />

      <label>Slug</label>
      <HInput
        model={model_editor.getModel("new")}
        field="slug"
        placeholder={original.url}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onSubmit();
          }
        }}
      />

      {/* <PasswordPortion onSubmit={onSubmit} /> */}

      <button className={clsx("btn btn-primary mt-4")} onClick={onSubmit}>
        Submit Changes
      </button>
    </div>
  );
  // TODO: Fix password.
}

function PasswordPortion(props: { onSubmit: () => void }) {
  const original_pswd = model_editor.useModel("original", "pswd").state;
  const pswd_enabled = model_editor.useModel("pswd_enabled").state;

  return (
    <div className={styles.password_portion}>
      <label className={styles.title}>Password</label>
      <Toggle className={styles.switch} model={model_editor} />
      <HInput
        model={model_editor.getModel("new")}
        field="pswd"
        placeholder={original_pswd}
        disabled={!pswd_enabled}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            props.onSubmit();
          }
        }}
      />
    </div>
  );
}
