import { ReactModel } from "df-react-model/dist/react-model/react-model";
import { KeySchema } from "zod";
import { TOneDomain } from "../api/types";

export function extractUrlDomain(urlString: string): string {
  try {
    const url = new URL(urlString);
    const hostname = url.hostname;

    return hostname;
  } catch (error) {
    console.error("Invalid URL:", error);
    return "";
  }
}

export function sanitizeUrl(urlString: string): string {
  if (
    urlString.substring(0, 8) !== "https://" &&
    urlString.substring(0, 7) !== "http://"
  ) {
    urlString = "https://" + urlString;
  }

  try {
    const test = new URL(urlString);
    return test.href;
  } catch (error) {
    console.error({ code: "[UH20]", error: error });
    return "";
  }
}

export function compileShortenedUrl(
  domain_id: string,
  slug: string,
  domain_list: TOneDomain[]
): string {
  const domain = domain_list.find((item) => item.id === domain_id);
  if (domain === undefined) {
    // TODO: consider how to handle this error.
    // throw Error("Invalid domain id.")
    return ``;
  } else {
    return `https://${domain.domain}/${slug}`;
  }
}

export function sanitizeSlug(slug: string): string {
  if (/_/.test(slug)) {
    return "";
  }
  return slug;
}
