import { TOneDomain } from "./types";
import { api_callAxios } from "./utils";

const mock_data = {
  list: [
    {
      id: "1",
      domain: "okurl.io",
    },
    {
      id: "2",
      domain: "gjw.us",
    },
  ],
};

export async function api_loadDomains(): Promise<TOneDomain[]> {
  const data = await api_callAxios("/domain/list_pub", "post", null, mock_data);
  return data.list as TOneDomain[];
}
